import { Button } from "@screencloud/screencloud-ui-components";
import { FunctionalComponent, h, RenderableProps } from "preact";
import { useCallback, useEffect, useRef, useState } from "preact/hooks";

import useSelect from "../../state/hooks/useSelect";

import { getEmbedToken } from "../../classes/authStorageManager";
import { clientEmbedViewerId, clientSessionId } from "../../classes/clientIdsManager";
import { embedLogToServer } from "../../api";
import { LoggingActions } from "../../api/types";
import config from "../../config";

import PageContainer from "../PageContainer";
import LoginPage from "../LoginPage";
import Player from "../Player";
import { Region } from "../../config";
import Debug from "../Debug";

import * as styles from "./styles.css";

interface ContentProps {
    embedId: string;
    region: Region;
    autoPlay?: boolean;
    debug?: boolean;
}

const log = (action: LoggingActions, region: Region, contentId?: string) => {
    if (contentId && region) {
        embedLogToServer(
            region,
            clientEmbedViewerId(),
            clientSessionId(),
            action,
            getEmbedToken(contentId)
        );
    }
};

const useStartCheckStillPlaying = (time: number, skip?: boolean) => {
    const [expired, setExpired] = useState(false);
    const timoutRef = useRef<NodeJS.Timeout>();
    useEffect(() => {
        if (time > 0 && !skip) {
            timoutRef.current = setTimeout(() => {
                setExpired(true);
            }, time);
            return () => {
                setExpired(false); // reset
                if (timoutRef.current) clearTimeout(timoutRef.current);
            };
        }
    }, [skip, time]);
    return expired;
};

const Content: FunctionalComponent<ContentProps> = ({
    region,
    autoPlay,
    debug
}: RenderableProps<ContentProps>) => {
    const [isPlay, setPlay] = useState(autoPlay);
    const [isShowContinuePlaying, setShowContinuePlaying] = useState(false);
    const [
        isFirstTimeShowingContinuePlayingDialog,
        setFirstTimeShowingContinuePlayingDialog
    ] = useState(true);
    const embedInfo = useSelect(state => state.embedInfo);
    const loggingPlayingRef = useRef<NodeJS.Timeout>(null);
    const skipCheckContinuePlaying = !isPlay;

    const startContinueLoggingPlaying = useCallback(() => {
        if (embedInfo && embedInfo.contentId) {
            loggingPlayingRef.current = setInterval(() => {
                log("playing", region, embedInfo.contentId);
            }, config.playerLogPlayingStatusTime);
        }
    }, [embedInfo, region]);

    const stopContinueLoggingPlaying = useCallback(() => {
        clearInterval(loggingPlayingRef.current);
    }, []);

    const timeToAction =
        isFirstTimeShowingContinuePlayingDialog && autoPlay
            ? config.playerRequireActionTimeFirstRound
            : config.playerRequireActionTime;

    if (useStartCheckStillPlaying(timeToAction, skipCheckContinuePlaying)) {
        setShowContinuePlaying(true);
        setPlay(false);
        setFirstTimeShowingContinuePlayingDialog(false);
        stopContinueLoggingPlaying();
    }

    if (!embedInfo) {
        return null;
    }

    let token: string | null | undefined;
    if (embedInfo.isEmbedPublic) {
        token = embedInfo.token;
    } else {
        token = getEmbedToken(embedInfo.contentId);
        if (!token) {
            // Private Content
            return (
                <LoginPage
                    orgName={embedInfo.orgName}
                    embedId={embedInfo.embedId}
                    region={region}
                    title={embedInfo.title}
                />
            );
        }
    }

    const startPlaying = () => {
        setPlay(true);
        if (embedInfo.contentId) {
            log("play", region, embedInfo.contentId);
            stopContinueLoggingPlaying();
            startContinueLoggingPlaying();
        }
    };

    const confirmContinuePlaying = () => {
        setPlay(true);
        setShowContinuePlaying(false);
        log("continue", region, embedInfo.contentId);
        stopContinueLoggingPlaying();
        startContinueLoggingPlaying();
    };

    return (
        <PageContainer title={`${embedInfo.title}`}>
            {debug && (
                <Debug
                    data={[
                        ["embedId", embedInfo.embedId],
                        ["title", embedInfo.title],
                        ["contentId", embedInfo.contentId],
                        ["contentType", embedInfo.contentType],
                        ["isEmbedEnabled", embedInfo.isEmbedEnabled ? "yes" : "no"],
                        ["isEmbedPublic", embedInfo.isEmbedPublic ? "yes" : "no"],
                        ["orgId", embedInfo.orgId],
                        ["orgName", embedInfo.orgName],
                        ["autoPlayer", autoPlay ? "yes" : "no"]
                    ]}
                />
            )}
            <div className={styles.playerWrapper}>
                <Player
                    contentId={embedInfo.contentId}
                    contentType={embedInfo.contentType}
                    token={token || ""}
                    region={region}
                    playback={isPlay ? "play" : "pause"}
                />
            </div>
            {!isPlay && !isShowContinuePlaying && (
                <PageContainer withLogo={true} backdrop={true}>
                    <h1 className={styles.orgName}>{embedInfo.orgName}</h1>
                    <h2 className={styles.contentTitle}>{embedInfo.title}</h2>
                    <Button primary onClick={startPlaying}>
                        Start Playing
                    </Button>
                </PageContainer>
            )}
            {isShowContinuePlaying && (
                <PageContainer withLogo={true} backdrop={true}>
                    <h1 className={styles.orgName}>{embedInfo.orgName}</h1>
                    <h2 className={styles.contentTitle}>{embedInfo.title}</h2>
                    <h2 className={styles.warning}>Are you still watching?</h2>
                    <br />
                    <Button onClick={confirmContinuePlaying}>Continue Playing</Button>
                </PageContainer>
            )}
        </PageContainer>
    );
};

export default Content;
