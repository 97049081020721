import { FunctionalComponent, h, RenderableProps } from "preact";
import { UUID } from "@screencloud/uuid";
import { useEffect, useRef } from "preact/hooks";
import { renderStudioPlayer } from "@screencloud/studio-player-sdk";
import { StudioPlayer } from "@screencloud/studio-player-sdk/lib/types";

import config, { Region } from "../../config";

import * as styles from "./styles.css";

interface PlayerProps {
    contentType: "playlist" | "channel";
    contentId: UUID;
    token: string;
    region: Region;
    playback: "play" | "pause";
}

const Player: FunctionalComponent<PlayerProps> = ({
    token,
    contentId,
    contentType,
    region,
    playback
}: RenderableProps<PlayerProps>) => {
    const targetNode = useRef<HTMLDivElement | null>();
    const studioPlayer = useRef<StudioPlayer | undefined>();

    useEffect(() => {
        if (targetNode.current !== null && !studioPlayer.current) {
            studioPlayer.current = renderStudioPlayer({
                playerUrl: config.studioPlayerUrls[region],
                target: targetNode.current,
                region,
                token,
                environment: config.environment,
                contentPath: `/${contentType}/${contentId}`,
                features: {
                    enablePlaybackControlInterface: true
                    // menuSplashEnabled: false,
                    // menusEnabled: true,
                },
                initPlaybackCommand: playback
            });
        }
    }, [contentId, contentType, region, token, playback, targetNode, studioPlayer]);

    switch (playback) {
        case "play":
            studioPlayer.current?.controls?.play();
            break;
        case "pause":
            studioPlayer.current?.controls?.pause();
            break;
    }

    return <div ref={targetNode} className={styles.player} />;
};

export default Player;
