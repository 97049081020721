import { FunctionalComponent, h, RenderableProps } from "preact";
import { useEffect } from "preact/hooks";
import { useGetEmbedInformation, useSetupLiveUpdate } from "./hooks";

import {
    removeEmbedToken,
    removeStudioToken,
    saveEmbedToken,
    saveStudioToken,
    validToken
} from "../../classes/authStorageManager";

import { Region } from "../../config";
import Loading from "../Loading";
import ErrorPage from "../ErrorPage";
import Content from "../Content";

import * as styles from "./styles.css";
import useDispatch from "../../state/hooks/useDispatch";
import { updateEmbedInfo } from "../../state/actions";

interface MainPageProps {
    embedId: string;
    region: Region;
}

/*
Optional url params for Embeddable Player
--------------------------------------------------------------------------------
autoplay (0|1)
    - auto play without require user click start playing
override_studio_user_token (JWT)
    - override User token that will use for verify to get Embed's token
override_embed_token (JWT)
    - override Embed token for fetch content data
example:
    - https://embed.dev.next.sc:8082/eu/TlGJY4jP3eZeGCaXmoF8?autoplay=1
 */
const getConfigFromQueryString = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const autoPlay = urlParams.get("autoplay") || undefined;
    const overrideStudioToken = urlParams.get("override_studio_user_token") || undefined;
    const overrideEmbedToken = urlParams.get("override_embed_token") || undefined;
    return {
        autoPlay,
        overrideStudioToken,
        overrideEmbedToken
    };
};

const MainPage: FunctionalComponent<MainPageProps> = (props: RenderableProps<MainPageProps>) => {
    const { embedId, region } = props;
    const [error, data, reload] = useGetEmbedInformation(embedId);
    // useSetupAutoReloadTokenWhenExpired(data, reload);
    const dispatch = useDispatch();

    useSetupLiveUpdate(region, reload, data?.orgId, data?.contentType, data?.contentId);

    const { autoPlay, overrideStudioToken, overrideEmbedToken } = getConfigFromQueryString();
    // if override tokens exist then do validation and save it to localStorage
    // if exist override token but invalid then don't use it
    if (data?.contentId && overrideEmbedToken && validToken(overrideEmbedToken)) {
        saveEmbedToken(data?.contentId, overrideEmbedToken);
    }
    if (data?.orgId && overrideStudioToken && validToken(overrideStudioToken)) {
        saveStudioToken(data?.orgId, overrideStudioToken);
    }

    useEffect(() => {
        if (embedId && data) {
            dispatch(updateEmbedInfo({ ...data, embedId }));
        }
    }, [embedId, data, dispatch]);

    // useEffect(() => {
    //     if (overrideStudioToken || overrideEmbedToken) {
    //         dispatch(
    //             updateTokens({ studioToken: overrideStudioToken, embedToken: overrideEmbedToken })
    //         );
    //     }
    // }, [dispatch, overrideStudioToken, overrideEmbedToken]);

    useEffect(() => {
        if (data && !data?.isEmbedEnabled) {
            // clear old token
            removeStudioToken(data.orgId);
            removeEmbedToken(data.contentId);
            // document.cookie = "express:sess.sig=; Path=/; domain=.dev.next.sc; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
            // document.cookie = "express:sess=; Path=/; domain=.dev.next.sc; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
        }
    }, [data]);

    if (error) {
        return <ErrorPage title="Error" topic="Sorry" message="Something went wrong." />;
    }

    if (!data) {
        return <Loading />;
    }

    const content = data?.isEmbedEnabled ? (
        <Content {...props} autoPlay={autoPlay == "1"} />
    ) : (
        <ErrorPage title="Error" topic="Sorry" message="Content is no longer available." />
    );

    return <div className={styles.mainWrapper}>{content}</div>;
};

export default MainPage;
