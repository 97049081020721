import { FunctionalComponent, h, RenderableProps } from "preact";
import { route } from "preact-router";
import { useCallback, useEffect, useRef, useState } from "preact/hooks";

import {
    getAuthToken,
    requestEmbedTokenAndSave,
    requestStudioTokenAndSave
} from "../../classes/authManager";
import { clientEmbedViewerId, clientSessionId } from "../../classes/clientIdsManager";

import { Region } from "../../config";
import PageContainer from "../PageContainer";
import { useGetEmbedInformation } from "../MainPage/hooks";
import ErrorPage from "../ErrorPage";
import {
    getEmbedToken,
    removeEmbedToken,
    removeStudioToken
} from "../../classes/authStorageManager";
import { embedLogToServer } from "../../api";

interface AuthCallbackPageProps {
    embedId: string;
    region: Region;
}

function redirectBackToStartPoint(embedId: string, region: Region) {
    const params = new URLSearchParams(window.location.search);
    const isFromPopupWindow = params.has("popup");
    if (isFromPopupWindow) {
        setTimeout(() => {
            window.close();
        }, 2000);
    } else {
        // TODO: check if got reload mor ethan 3 time in 5s stop to do it again!!!!
        setTimeout(() => {
            route(`/${region}/${embedId}`);
        }, 2000);
    }
}

const AuthCallbackPage: FunctionalComponent<AuthCallbackPageProps> = (
    props: RenderableProps<AuthCallbackPageProps>
) => {
    const { embedId, region } = props;
    const [status, setStatus] = useState("Processing...");
    const [error, data] = useGetEmbedInformation(embedId);
    const isUnmountedRef = useRef(false);
    const getStudioToken = useCallback(async () => {
        if (!data || !data.contentId) {
            return null;
        }

        try {
            if (isUnmountedRef.current) {
                return;
            }

            removeStudioToken(data?.orgId);

            const authToken = await getAuthToken();
            if (!authToken) {
                redirectBackToStartPoint(embedId, region);
                return;
            }

            if (authToken && data?.orgId) {
                const studioToken = await requestStudioTokenAndSave(
                    embedId,
                    authToken,
                    data.orgId,
                    region,
                    clientEmbedViewerId(),
                    clientSessionId()
                );

                if (!studioToken) {
                    redirectBackToStartPoint(embedId, region);
                    return;
                }

                removeEmbedToken(data.contentId);

                // get embed token for playing private content
                const embedToken = await requestEmbedTokenAndSave(
                    data.contentType,
                    data.contentId,
                    data.orgId,
                    studioToken,
                    region,
                    clientEmbedViewerId(),
                    clientSessionId()
                );

                if (embedToken !== null) {
                    if (data.contentId) {
                        setStatus("Succeed, redirect back...");
                        await embedLogToServer(
                            region,
                            clientEmbedViewerId(),
                            clientSessionId(),
                            "authenticate",
                            getEmbedToken(data.contentId)
                        );
                        redirectBackToStartPoint(embedId, region);
                        return;
                    }
                } else {
                    throw new Error("Could not fetch studio token.");
                }
            } else {
                throw new Error("Auth token not found.");
            }
        } catch (e) {
            if (!isUnmountedRef.current) {
                if (data?.orgId) removeStudioToken(data.orgId);
                if (data?.contentId) removeEmbedToken(data.contentId);
                // setStatus("Something went wrong");
                redirectBackToStartPoint(embedId, region);
                return;
            }
            // TODO: handle this error
            console.log("callback process error", e);
        }
    }, [embedId, data, region, status]);

    useEffect(() => {
        getStudioToken();
    }, [getStudioToken]);

    useEffect(() => {
        return (): void => {
            isUnmountedRef.current = true;
        };
    }, []);

    if (error) {
        return <ErrorPage title="Error" topic="Sorry" message="Something went wrong." />;
    }

    return (
        <PageContainer title="Authentication" withLogo={true}>
            <h1>{status}</h1>
        </PageContainer>
    );
};

export default AuthCallbackPage;
